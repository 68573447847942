/* You can add global styles to this file, and also import other style files */

@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/material/theming';
@use "node_modules/@coolon/angular-ui-powerups/assets/styles" as aup;
@use "lib-common-image";
@use "lib-common-grecapcha";
@use "lib-common-aup-router-outlet";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/global-variables";
@import "./styles/page";
@import "./styles/page-slices";
@import "./styles/buttons";
@import "./styles/text-styles";
@import "./styles/list-styles";
@import "./styles/nav-styles";
@import "./styles/testimonial-styles";
@import "./styles/callout-styles";
@import "./styles/image-frame";


$md-success: (
  50 : #e4feea,
  100 : #bbfdcc,
  200 : #8efbaa,
  300 : #61f987,
  400 : #3ff86e,
  500 : #1df754,
  600 : #1af64d,
  700 : #15f543,
  800 : #11f33a,
  900 : #0af129,
  A100 : #ffffff,
  A200 : #e7ffea,
  A400 : #b4ffbc,
  A700 : #9bffa5,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$typography: mat.define-typography-config();

$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$yellow-palette);
$warn: mat.define-palette(mat.$red-palette);
$success: mat.define-palette($md-success);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

$background: map.get($theme, background);
$foreground: map.get($theme, foreground);

@include mat.core($typography);
@include mat.all-component-themes($theme);
@include aup.apply-light-theme($theme, $typography);


@import "./assets/fonts/material-design-icons/material-icons.css";


html, body {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

body > *:first-child {
  flex: 1;
}
