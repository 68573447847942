:root {
  --spa-common-image-frame-border-radius: 4px;
}

@mixin image-frame() {
  --color: var(--spa-common-image-frame-border-color, currentColor);
  border: var(--spa-common-image-frame-border-width, 6px) solid var(--color);
  border-radius: var(--spa-common-image-frame-border-radius, 4px);
  box-sizing: border-box;
}

.image-frame {
  @include image-frame;
}

img {
  max-width: 100%;
  height: auto;
}
