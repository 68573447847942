/**
See http://astronautweb.co/snippet/font-awesome/ for content codes.
$content: "\f137"
*/
@mixin font-awesome-before(
  $content,
  $icon-width: 1.2em,
  $color: auto,
  $hover-color: false
) {
  padding-left: $icon-width;

  &:before {
    color: $color;
    content: $content;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-left: -$icon-width;
    width: $icon-width;
  }

  @if $hover-color {
    &:hover {
      &:before {
        color: $hover-color;
      }
    }
  }
}

/**
See http://astronautweb.co/snippet/font-awesome/ for content codes.
$content: "\f137"
*/
@mixin font-awesome-after($content, $color: inherit, $hover-color: false) {
  $icon-width: 1.2em;
  padding-right: $icon-width;

  &:after {
    color: $color;
    content: $content;
    font-family: 'Font Awesome 5 Free';
    display: inline-block;
    margin-rigth: -$icon-width;
    width: $icon-width;
  }

  @if $hover-color {
    &:hover {
      &:before {
        color: $hover-color;
      }
    }
  }
}
