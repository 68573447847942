button, .button {
  @apply font-condensed py-1.5 px-4 rounded;
  @apply focus:outline-none;
  @apply text-base inline-block;
  @apply py-1.5;

  &[buttonStyle='raised'] {
    //&[color=blue] { the default
    @apply bg-teal-500 text-white;
    @apply hover:bg-teal-600 focus:border-teal-700;
    //}

    &[color='light-blue'] {
      @apply bg-blue-500 text-white;
      @apply hover:bg-blue-600;
    }
    &[color='orange'] {
      @apply bg-orange-700 text-white;
      @apply hover:bg-orange-800;
    }
  }

  &[buttonStyle='outline'] {
    //&[color="blue"] {
    @apply text-gray-600 border hover:text-gray-700 hover:bg-gray-100 focus:border-gray-500;
    //}

    &[color='white'] {
      @apply text-white border border-current hover:bg-blue-600;
    }

    &[color='light-blue'] {
      @apply border border-current hover:bg-blue-600;
    }
    &[color='orange'] {
      @apply border border-current hover:bg-orange-700;
    }
    &[color='warn'] {
      @apply border border-current hover:bg-red-500;
    }
  }

  //<button type="button" class="btn-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline bg-purple-700 hover:bg-purple-900 text-white font-normal py-2 px-4 mr-1 rounded">Button</button>
  //<button type="button" class="btn-outline-primary transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-purple-700 hover:bg-purple-700 text-purple-700 hover:text-white font-normal py-2 px-4 rounded">Button Outline</button>
}

//a:not(.button):hover {
//  @apply underline;
//}

