
// Override AUP router outlet default for apps so pages don't scroll.
.router-outlet-container {
  flex: 1;
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  min-height: auto !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;

  > * {
    position: relative !important;
  }
}
