h1, h1, h3 {
  @apply font-condensed;
  text-rendering: optimizeLegibility;
}

h1 {
  font-weight: 600;
  @apply text-2xl md:text-3xl mb-6;
}

h2 {
  //font-family: "Roboto Condensed", Roboto, sans-serif;
  @apply text-xl md:text-2xl mb-6;
  font-weight: 300;
  @apply text-gray-500;
  @apply mb-3 md:mb-6;
}

h3 {
  //font-family: 'Nunito', Roboto, sans-serif;
  @apply text-lg md:text-xl mb-6;
  font-weight: 600;
  @apply text-gray-500;
  @apply mb-3 md:mb-3;
}

h4 {
  //font-family: 'Nunito', Roboto, sans-serif;
  font-weight: bolder;
  @apply text-gray-500;
  @apply mb-2 md:mb-2;
}


p, li {
  @apply text-gray-600 font-thin text-base;
  text-rendering: optimizeLegibility;
}


$blockElements: p, ol, ul;

@each $e in $blockElements {
  //
  #{$e} + h2, #{$e} + h3, #{$e} + h4,  {
    @apply mt-6 mb-2;
  }

  @each $e2 in $blockElements {
    #{$e} + #{$e2} {
      @apply mt-3;
    }
  }
}



