// We use this to create equilateral triangles
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if ($triangle-direction == top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if ($triangle-direction == bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if ($triangle-direction == left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if ($triangle-direction == right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}
