@mixin testimonial-content() {
  spa-common-testimonial {
    .content {
      @content
    }
  }
}

@mixin testimonial-ornament() {
  spa-common-testimonial {
    .ornament {
      @content
    }
  }
}

@mixin testimonial-author() {
  spa-common-testimonial {
    .author {
      @content;
    }
  }
}


@mixin testimonial-defaults {
  @include testimonial-ornament {
    @apply text-black opacity-5;
  }

  @include testimonial-content {
    @apply text-base;
  }
  @include testimonial-author {
    @apply text-gray-600 font-condensed;
  }

}
