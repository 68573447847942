@use "lib-common-nav";
@import "lib-common-css-triangles";

rps-page-header {

  @include lib-common-nav.place-link-styles {
    @apply text-teal-800 px-2 py-3 font-condensed font-thin text-base cursor-pointer relative inline-block;
    --width:6px;

    &.active::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left:50%;
      margin-left: calc(-1*(var(--width))/2);
      bottom: calc((var(--width))/2);
      @include css-triangle(var(--width), white, top);
    }
  }
}

.nav-bar-popup {
  @include lib-common-nav.place-link-styles {
    @apply text-teal-800 px-4 py-3 font-condensed font-thin text-base;
    &.active{
      //@apply text-black;
    }
  }

}

spa-common-mobile-side-nav {

  @include lib-common-nav.place-link-styles {
    @apply py-3 font-thin text-base;
    @apply text-teal-800 cursor-pointer;
    padding-left: var(--default-padding);
    padding-right: var(--default-padding);
    &.active {
      @apply font-medium;
    }
  }

}
