
:root {
  --nav-bar-height: 180px;
  --mobile-nav-bar-height: 68px;
  --default-padding: 16px;

  --pinstripe-border-color: rgba(0, 0, 0, 0.2);
  --pinstripe-border: 1px solid var(--pinstripe-border-color);
  --pinstripe-border-dashed: 1px dashed var(--pinstripe-border-color);

  --sub-header-height: 48px;
}


