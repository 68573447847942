@use "./lib-common-font-awesome" as fa;

@mixin ul-defaults {
  ul {
    margin-left: 1em;
    @include li-base {
      list-style-type: disc;
      @content;
    }
    > li + li {
      @apply mt-1;
    }
  }
}

@mixin li-base() {
  > li {
    @apply text-base;
    @content;
  }
}

//$list-settings:(
//  "font":
//  "icon-color":
//);

@mixin li-fa($faIcon, $padding-left: 1.5em) {
  margin-left: 0;
  padding-left: 0;
  @include li-base {
    list-style-type: none;
    @include fa.font-awesome-before($faIcon, $icon-width: $padding-left);
    @content;
  }
}

@mixin li-fa-check-circle {
  @include li-fa('\f058')
}

@mixin li-fa-check {
  @include li-fa('\f00c')
}

@mixin li-fa-info-circle {
  @include li-fa('\f05a')
}

@mixin li-fa-icon-style {
  > li:before {
    @content;
  }
}

@mixin li-item-style($color) {
  > li {
    @content;
  }
}



