//@use "lib-common-lists";

.callout {
  @apply p-6 border border-gray-200;
  border-radius: var(--spa-common-image-frame-border-radius);
}
.callout-grey {
  @extend .callout;
  @apply bg-gray-50;
}
