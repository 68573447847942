@use "lib-common-lists";

@include lib-common-lists.ul-defaults;

.list-check-circle ul {
  @include lib-common-lists.li-fa-check-circle;
  @include lib-common-lists.li-fa-icon-style {
    @apply text-teal-100;
  }
}
.list-check ul {
  @include lib-common-lists.li-fa-check;
  @include lib-common-lists.li-fa-icon-style {
    @apply text-teal-100;
  }
}
